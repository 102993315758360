import axios from "axios";
import { getToken, clearLocalStorage } from "./local-storage.service";
import router from "@/router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + "/api",
});

instance.interceptors.request.use((config) => {
  const configure = config;
  const token = getToken();
  if (token) {
    configure.headers.Authorization = `Bearer ${token}`;
  }
  return configure;
});

instance.interceptors.response.use(undefined, (err) => {
  if (err.response.status === 401) {
    clearLocalStorage();
    router.push({ name: "login", params: { error: err.response } });
  }

  return Promise.reject(err);
});

export default instance;
